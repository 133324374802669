import axios from "axios";
import { ref } from "vue"
// import router from "../router";

export default function useSite() {
    
    const meta = ref([]);
    const status = ref(Boolean);
    const error = ref('');
    const message = ref('');
    const paramsSearch = ref({});

    const marcas = ref([]);

    const produtos = ref([]);
    const produtosResumo = ref([]);
    const produto = ref([]);

    ///// marcas //////
    const getMarcas = async (page=1) => {
        const qs = new URLSearchParams(paramsSearch.value);
        let response = await axios.get('/site/marcas?page='+page+'&'+qs);
        marcas.value = response.data.data;
        meta.value = response.data.meta;
    };

    ///// produtos //////
    const getProdutos = async (page=1) => {
        
        if(parseInt(page) > 0){
            const qs = new URLSearchParams(paramsSearch.value);
            let response = await axios.get('/site/produtos?page='+page+'&'+qs);
            produtos.value = response.data.data;
            meta.value = response.data.meta;
    
            response = await axios.get('/site/produtosResumo?page='+page+'&'+qs);
            produtosResumo.value = response.data.data;

            window.scroll({top: 0});
        }

    };
    const getProduto = async (id) => {
        let response = await axios.get('/site/produtos/' + id);
        produto.value = response.data.data;
    };

    // const getLinkWhats = async () => {
    //     const data = produto.value;
    //     const qs = encodeURI([data.id, data.descricao].join(' '));
    //     const ret = `https://wa.me/555199999999?text='${qs}`;
    //     return ret;
    // }

    return {

        meta,
        error,
        status,
        message,
        paramsSearch,

        marcas,
        getMarcas,

        produtos,
        produtosResumo,
        produto,
        getProdutos,
        getProduto,

        // getLinkWhats
    }
}